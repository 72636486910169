import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import LoginPage from './views/Login.vue';
import GuestsPage from './views/Guests.vue';
import GuestCenterPage from './views/GuestCenter.vue';
import FeedbackPage from './views/Feedback.vue';
import DashboardPage from './views/Dashboard.vue';
import DrawManagementPage from './views/DrawManagement.vue';
import CreateDrawPage from './views/CreateDraw.vue';
import DrawOrderPage from './views/DrawOrder.vue';
import ContentCenterPage from './views/ContentCenter.vue';
import GuestExportPage from './views/Data/GuestExport.vue';
import adCenterPage from './views/Ad/AdCenter.vue';
import adEditPage from './views/Ad/AdEdit.vue';
import AdSpacePage from './views/Ad/AdSpace.vue';

import NotificationListPage from './views/Market/NotificationList';
import NotificationCreatePage from './views/Market/NotificationCreate';
import SurveyCreatePage from './views/Market/SurveyCreate.vue';
import SurveyListPage from './views/Market/SurveyList.vue';
import BoostMerchantListPage from './views/Market/BoostMerchantList.vue';
import BoostSalesListPage from './views/Market/BoostSalesList.vue';
import BoostRobotListPage from './views/Market/BoostRobotList.vue';
import BoostSalesHistoryPage from './views/Market/BoostSalesHistory.vue';
import BoostTransactionListPage from './views/Market/BoostTransactionList.vue';

import MasterListPage from './views/Master/MasterList.vue';
import MasterEditPage from './views/Master/MasterEdit.vue';

import BirdPage from './views/Monitor/Bird.vue';
import SystemPage from './views/Monitor/System.vue';
import LiveChatPage from './views/Monitor/LiveChat.vue';
import WaitingTimePage from './views/Monitor/WaitingTime.vue';
import SurvetPage from './views/Survey.vue';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/drawOrder', component: DrawOrderPage },
        { path: '/createDraw', component: CreateDrawPage },
        { path: '/drawManagement', component: DrawManagementPage },
        { path: '/dashboard', component: DashboardPage },
        { path: '/contentCenter', component: ContentCenterPage },
        { path: '/login', component: LoginPage },
        { path: '/guests', component: GuestsPage },
        { path: '/feedback', component: FeedbackPage },
        { path: '/survey', component: SurvetPage },
        { path: '/guestcenter', component: GuestCenterPage },

        { path: '/data/export', component: GuestExportPage },
        { path: '/adlist', component: adCenterPage },
        { path: '/adedit', component: adEditPage },
        { path: '/adspace', component: AdSpacePage },

        { path: '/market/list', component: NotificationListPage },
        { path: '/market/create', component: NotificationCreatePage },
        { path: '/market/survey/list', component: SurveyListPage },
        { path: '/market/survey/create', component: SurveyCreatePage },
        { path: '/market/boost/merchants', component: BoostMerchantListPage },
        { path: '/market/boost/sales', component: BoostSalesListPage },
        { path: '/market/boost/robots', component: BoostRobotListPage },
        { path: '/market/boost/history', component: BoostSalesHistoryPage },
        { path: '/market/boost/transaction', component: BoostTransactionListPage },
        
        { path: '/master/list', component: MasterListPage },
        { path: '/master/edit', component: MasterEditPage },

        { path: '/monitor/bird', component: BirdPage },
        { path: '/monitor/system', component: SystemPage },
        { path: '/monitor/livechat', component: LiveChatPage },
        { path: '/monitor/waitingtime', component: WaitingTimePage },
        
        { path: '*', redirect: '/login' }
    ]
});

router.beforeEach(async (to, from, next) => {
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
